<template>
  <div class="beneficiary-details mb-4">
    <div :class="initialLoading && 'hide'">
      <a class="btn text-themed go-back" @click="goBack()">{{ $t("flexben.general.go_back") }}</a>
      <div class="row">
        <div class="col-12 col-md-5 col-lg-7 d-flex align-items-center flexben-page-title">
          <h1 class="m-0">{{ edit ? beneficiaryData.name : $t("flexben.benefit.new_beneficiary") }}</h1>
          <div class="mt-2">
            <span v-if="edit" :class="'beneficiary-'+beneficiaryData.status" class="ml-2">{{ $t("flexben.general.status_type." + beneficiaryData.status) }}</span>

            <span v-if="edit" class="d-block ml-2">
            {{ displayDate }}
             </span>
          </div>
        </div>
        <div class="col-12 col-md-7 col-lg-5">
          <div class="d-flex text-right justify-content-end flex-column flex-md-row">
            <button v-if="(edit && (beneficiaryData.status==='active' ||beneficiaryData.status==='suspended'))"
                    class="btn btn-delete mt-2 mt-md-0"
                    @click="triggerAction('delete')">
              <font-awesome-icon icon="trash-alt"/>
              {{$t("flexben.benefit.delete")}}
            </button>
            <button v-if="(edit && beneficiaryData.status==='active')"
                    class="btn btn-suspend mt-2 mt-md-0"
                    @click="triggerAction('suspend')">
              <font-awesome-icon icon="ban"/>
              {{$t("flexben.benefit.suspended")}}
            </button>
            <button v-if="(edit && (beneficiaryData.status==='active' ||beneficiaryData.status==='suspended'))"
                    class="btn btn-themed-o mt-2 mt-md-0 mr-2 background-grey"
                    @click="triggerAction('toInvited')">
              {{$t("flexben.benefit.change_status_to_invited")}}
            </button>
            <button v-if="(edit && beneficiaryData.status==='pending_delete')"
                    class="btn btn-themed mt-2 mt-md-0 mr-2 background-grey"
                    @click="triggerAction('reActivate')">
              {{$t("flexben.benefit.change_status_to_reActivate")}}
            </button>
            <button v-if="(edit && beneficiaryData.status==='suspended')"
                    class="btn btn-reactivate mt-2 mt-md-0"
                    @click="triggerAction('activate')">
              <font-awesome-icon icon="check"/>
              {{$t("flexben.benefit.reactivate")}}
            </button>
            <button v-if="(edit && beneficiaryData.status==='invited')"
                    class="btn btn-reinvite mt-2 mt-md-0"
                    @click="triggerAction('reinvite')">
              <font-awesome-icon icon="envelope"/>
              {{$t("flexben.benefit.resend_invitation")}}
            </button>
            <button class="btn btn-themed mt-3 mt-md-0" @click="triggerAction('form_submit','details')">
              {{ $t("flexben.general.btn.save") }}
            </button>
          </div>
        </div>
      </div>
      <div class="row mt-2 d-flex align-items-center">
        <div class="col-12 col-md-4 col-lg-3 beneficiary-info">
          <img alt="company icon" class="company-icon mr-2"
               src="@/assets/images/flexben/utils/new-beneficiary-icon.png">
          <span class="mt-2">
            {{ form_options.company.name }}
          </span>

          <div class="list-inline ">
            <div class="icon-group text-bold">
              {{ $t('flexben.beneficiaries.company.taxation_subtitle') }}:
            </div>
            <ul v-html="_getBenefitConfigInfo()"></ul>
          </div>

        </div>

        <div v-if="edit && form_options.company.type_code!=='special_offers'" class="col-12 col-md-8 col-lg-9">
          <div class="d-flex align-items-center">
            <ul :class="{'text-ellipsis-100':!showAllBenefits}" class="list-inline my-auto">
              <li v-for="benefit in beneficiaryData.available_benefits" :key="benefit.id"
                  class="list-inline-item">
                <span :style="{backgroundColor: benefit.color}" class="color-bullet"></span>
                {{ benefit.name }}
              </li>
            </ul>
            <span v-if="beneficiaryData.benefitsCharLength >100" class="text-themed cursor-pointer ml-3"
                  @click="benefitsExpand()"> {{ showAllBenefits ?
            $t('flexben.general.btn.hide') : $t('flexben.general.btn.see_more') }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="checkNotEmptyObj(errorBag.details)||checkNotEmptyObj(errorBag.budget)||checkNotEmptyObj(errorBag.telework_budget)||checkNotEmptyObj(errorBag.ppp3_budget)||checkNotEmptyObj(errorBag.time_sheet)"
        class="row px-3 mt-3">
        <div class="col-12 text-bold text-danger alert alert-danger mb-0">
          {{ $t('flexben.general.errors_exist') }}
        </div>
      </div>
      <component
        :is="form_options.company.type_code==='special_offers' ? 'OfferBeneficiaryDetails' : 'BeneficiaryDetails'"
        :benefit_groups="benefit_groups"
        :delivery_addresses="delivery_addresses"
        :edit="edit"
        :errorBag="errorBag.details"
        :fixed_benefit_groups="fixed_benefit_groups"
        :formData="this.form_data"
        :form_options="form_options"
        :has_default_values="has_default_values"
        :status="beneficiaryData.status"></component>

      <div class="row mt-md-3">
        <div class="col-12">
          <div class="d-flex text-right justify-content-end flex-column flex-md-row">
            <button v-if="(edit && (beneficiaryData.status==='active' ||beneficiaryData.status==='suspended'))"
                    class="btn btn-delete mt-2 mt-md-0"
                    @click="triggerAction('delete')">
              <font-awesome-icon icon="trash-alt"/>
              {{$t("flexben.benefit.delete")}}
            </button>
            <button v-if="(edit && beneficiaryData.status==='active')"
                    class="btn btn-suspend mt-2 mt-md-0"
                    @click="triggerAction('suspend')">
              <font-awesome-icon icon="ban"/>
              {{$t("flexben.benefit.suspended")}}
            </button>
            <button v-if="(edit && beneficiaryData.status==='suspended')"
                    class="btn btn-reactivate mt-2 mt-md-0"
                    @click="triggerAction('activate')">
              <font-awesome-icon icon="check"/>
              {{$t("flexben.benefit.reactivate")}}
            </button>
            <button v-if="(edit && (beneficiaryData.status==='active' ||beneficiaryData.status==='suspended'))"
                    class="btn btn-themed-o mt-2 mt-md-0 mr-2 background-grey"
                    @click="triggerAction('toInvited')">
              {{$t("flexben.benefit.change_status_to_invited")}}
            </button>
            <button v-if="(edit && beneficiaryData.status==='pending_delete')"
                    class="btn btn-themed mt-2 mt-md-0 mr-2 background-grey"
                    @click="triggerAction('reActivate')">
              {{$t("flexben.benefit.change_status_to_reActivate")}}
            </button>
            <button v-if="(edit && beneficiaryData.status==='invited')"
                    class="btn btn-reinvite mt-2 mt-md-0"
                    @click="triggerAction('reinvite')">
              <font-awesome-icon icon="envelope"/>
              {{$t("flexben.benefit.resend_invitation")}}
            </button>
            <button class="btn btn-themed mt-3 mt-md-0" @click="triggerAction('form_submit','details')">
              {{ $t("flexben.general.btn.save") }}
            </button>
          </div>
        </div>
      </div>


      <BeneficiaryBudget v-if="flxModuleCan('flexible_benefits', form_options.company_module_permissions)"
                         key="budget"
                         :budgetData="this.form_data.budget"
                         :disableUntil="disableUntil"
                         :errorBag="errorBag.budget"
                         type="budget"/>

      <div v-if="flxModuleCan('flexible_benefits', form_options.company_module_permissions) && edit" class="row mt-3">
        <div class="col-12 d-flex">
          <button class="btn btn-themed mt-3 mt-md-0 ml-md-auto" @click="triggerAction('form_submit','budget')">
            {{ $t("flexben.beneficiaries.btn.save_budget") }}
          </button>
        </div>
      </div>

      <BeneficiaryBudget v-if="edit && flxModuleCan('flexible_benefits', form_options.company_module_permissions)"
                         key="ppp3_budget"
                         :budgetData="this.form_data.ppp3_budget"
                         :disableUntil="disableUntil"
                         :errorBag="errorBag.ppp3_budget"
                         type="ppp3_budget"/>
      <div v-if="edit && flxModuleCan('flexible_benefits', form_options.company_module_permissions)" class="row mt-3">
        <div class="col-12 d-flex">
          <button class="btn btn-themed mt-3 mt-md-0 ml-md-auto"
                  @click="triggerAction('form_submit','ppp3_budget')">
            {{ $t("flexben.beneficiaries.btn.save_ppp3_budget") }}
          </button>
        </div>
      </div>

      <BeneficiaryBudget v-if="edit && flxModuleCan('telework', form_options.company_module_permissions)"
                         key="telework_budget"
                         :budgetData="this.form_data.telework_budget"
                         :disableUntil="disableUntil"
                         :errorBag="errorBag.telework_budget"
                         :lockedDate="lockedDate"
                         type="telework_budget"/>

      <div v-if="edit && flxModuleCan('telework', form_options.company_module_permissions)" class="row mt-3">
        <div class="col-12 d-flex">
          <button class="btn btn-themed mt-3 mt-md-0 ml-md-auto"
                  @click="triggerAction('form_submit','telework_budget')">
            {{ $t("flexben.beneficiaries.btn.save_telework_budget") }}
          </button>
        </div>
      </div>


      <BeneficiaryTimeSheet
        v-if="flxModuleCan('flexible_benefits', form_options.company_module_permissions) && edit && form_options.up_dejun_enabled"
        :errorBag="errorBag.time_sheet"
        :form_options="form_options"
        :timeSheetData="form_data.time_sheet"/>
      <div
        v-if="flxModuleCan('flexible_benefits', form_options.company_module_permissions) && edit && form_options.up_dejun_enabled"
        class="row mt-3">
        <div class="col-12 d-flex">
          <button class="btn btn-themed mt-3 mt-md-0 ml-md-auto" @click="triggerAction('form_submit','time_sheet')">
            {{ $t("flexben.beneficiaries.btn.save_time_sheet") }}
          </button>
        </div>
      </div>

      <BeneficiaryHistory
        v-if="edit && flxModuleCan(['flexible_benefits','telework'],form_options.company_module_permissions)"
        :company_module_permissions="form_options.company_module_permissions"
        :history="beneficiaryData.history"
        :isAdmin="isAdmin"
        @changePerPage="changePerPage"
        @disableTopUps="disableTopUps"
        @pageChangedHistoryBudget="pageChangedHistoryBudget"
        @pageChangedHistoryPpp3Budget="pageChangedHistoryPpp3Budget"
        @pageChangedHistoryRequests="pageChangedHistoryRequests"
        @pageChangedHistoryTeleworkBudget="pageChangedHistoryTeleworkBudget"
        @pageChangedHistoryTimeSheets="pageChangedHistoryTimeSheets"/>
    </div>
    <StatusChangeModal ref="status_change_modal"
                       @confirmAction="confirmAction"/>
    <content-loader :loaderError="loaderError" :loaderVisible="loading"></content-loader>
  </div>
</template>

<script>
import BeneficiaryDetails from "./partials/administration/BeneficiaryDetails";
import OfferBeneficiaryDetails from "./partials/administration/OfferBeneficiaryDetails";
import BeneficiaryBudget from "./partials/administration/BeneficiaryBudget";
import BeneficiaryTimeSheet from "./partials/administration/BeneficiaryTimeSheet";
import BeneficiaryHistory from "./partials/administration/BeneficiaryHistory";
import httpFlexbenService from "@/http/http-flexben.js";
import moment from "moment";
import StatusChangeModal from "./modals/StatusChangeModal";

export default {
  name: "BeneficiaryAdministration",
  components: {
    BeneficiaryHistory,
    BeneficiaryTimeSheet,
    BeneficiaryBudget,
    BeneficiaryDetails,
    OfferBeneficiaryDetails,
    StatusChangeModal
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
      required: true
    },
  },
  data() {
    return {
      httpService: httpFlexbenService.beneficiaries,
      initialLoading: true,
      loading: true,
      loaderError: false,
      isAdmin: false,
      beneficiaryId: null,
      companyId: null,
      company: {
        type_code: null
      },
      form_options: {
        benefit_groups: [],
        fixed_benefit_groups: [],
        delivery_addresses: [],
        invitation_channels: [],
        company: {},
        company_module_permissions: [],
        max_month_working_days: 0,
        top_up_fixed_values: {
          enable: false,
          values: [],
        },
        top_up_set_ticket_nr: {
          enable: false,
          ticket_value: null,
        },
        up_dejun_enabled: false
      },
      benefit_groups: [],
      fixed_benefit_groups: [],
      delivery_addresses: [],
      isFormEdited: false,

      last_employment_date: null,

      errorBag: {
        details: {},
        budget: {},
        telework_budget: {},
        time_sheet: {},
      },

      form_data: {
        save_section: null,
        company_id: null,
        fixed_benefit_group_id: null,
        first_name: '',
        last_name: '',
        cnp: null,
        phone_number: null,
        invitation_email: null,
        invitation_channel: 'email',
        company_benefit_group_id: null,
        employee_number: null,
        department: null,
        job_title: null,
        cost_center: null,
        working_point: null,
        underage_children_nr: 0,
        up_ro_address_id: null,
        income_tax_percent: null,
        cass_percent: null,
        cas_percent: null,
        up_dejun_enable_auto_topup: false,
        up_dejun_ticket_value: null,
        up_dejun_ticket_count: null,
        ppp3_deduction_limit: null,
        ppp3_personal_deduction_limit: null,
        medical_insurance_deduction_limit: null,
        deleted_at : null,
        status : null,
        thresholds: {
          gym_membership: {}
        },
        budget: {
          total_budget: 0,
          budget_expiration_date: null,
          updated_at: null,
        },
        telework_budget: {
          total_budget: 0,
          budget_expiration_date: null,
          updated_at: null,
        },
        ppp3_budget: {
          total_budget: 0,
          budget_expiration_date: null,
          updated_at: null,
        },
        time_sheet: {
          working_days: 0,
          consumed_days: 0,
          month: null,
          year: null,
          updated_at: null,
        },
        lastAutoTopupEditTME:[],
      },
      beneficiaryData: {
        name: null,
        status: null,
        benefitsCharLength: 0,
        available_benefits: [],
        benefit_configuration: {},
        budget: {
          total_budget: 0.00,
          budget_expiration_date: null,
          updated_at: null,
        },
        time_sheet: {
          working_days: 0,
          consumed_days: 0,
          month: null,
          year: null,
          updated_at: null,
        },
        history: {
          budgets: {data: [], current_page: 1, to: 1, perPage: 10, lastPage: 1, total: 1},
          telework_budgets: {data: [], current_page: 1, to: 1, perPage: 10, lastPage: 1, total: 1},
          ppp3_budgets: {data: [], current_page: 1, to: 1, perPage: 10, lastPage: 1, total: 1},
          time_sheets: {data: [], current_page: 1, to: 1, perPage: 10, lastPage: 1, total: 1},
          requests: {data: [], current_page: 1, to: 1, perPage: 10, lastPage: 1, total: 1},
          auto_top_ups: [],
        },
        last_employment_date : null,
        activated_at : null,
      },
      has_default_values: {
        income_tax_percent: false,
        cass_percent: false,
        cas_percent: false,
        ppp3_deduction_limit: false,
        ppp3_personal_deduction_limit: false,
        medical_insurance_deduction_limit: false,
        gym_membership_threshold: false
      },
      extra: {
        max_month_working_days: 0,
        top_up_fixed_values: {
          enable: false,
          values: [],
        },
        top_up_set_ticket_nr: {
          enable: false,
          ticket_value: null,
        },
        up_dejun_enabled: false
      },
      company_module_permissions: [],
      disableUntil: {to: moment().toDate()},
      lockedDate: {to: moment().endOf('month')},
      showAllBenefits: false,
      modal: {
        open: false,
        statusChange: '',
        formSuccess: false,
        action: null,
        endMessage: '',
        disableUntil: {to: moment().toDate()},
        companyName: null,
      },
    }
  },
  created() {
    let userRole = this.flxUserLevel().userLevel;
    let today = moment().startOf('day');
    if (userRole === 'client') {
      this.isAdmin = false;

      this.disableUntil = {
        to: moment(today).add(2, 'days').toDate()
      };
    } else if (userRole === 'admin') {
      this.isAdmin = true;
      this.disableUntil = {
        to: moment(today).toDate()
      };
    }

    if (this.edit) {
      this.beneficiaryId = this.$route.params.beneficiary_id;
      this._loadBeneficiary();
    } else if (localStorage.getItem('selectedCompanyId')) {
      this.companyId = localStorage.getItem('selectedCompanyId');
      this._getCompanyData();
    } else {
      // todo get from cookie
      window.location.href = '/multibeneficii/beneficiaries';
    }
  },
  watch: {
    form_data: {
      handler: function (val) {
        this.isFormEdited = true;
      },
      deep: true
    },
    initialLoading: {
      handler(val) {
        this.isFormEdited = false;
      }
    },
  },
  methods: {
    _getCompanyData() {
      if (this.companyId == null) {
        return;
      }

      this.httpService.getCreateFormOptions(this.companyId)
        .then((response) => {
          this.form_options = response.data
          this.form_data.company_id = response.data.company.id;

          this.initialLoading = false;
          this.loading = false;
        })
        .catch((error) => {
          this.initialLoading = false
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false
          }
        });
    },
    _loadBeneficiary() {
      if (this.beneficiaryId == null) {
        this.loaderError = true;
        return;
      }
      this.httpService.loadBeneficiary(this.beneficiaryId)
        .then((response) => {
          // beneficiary form data
          this.form_data.lastAutoTopupEditTME = response.data.auto_topup_history.lastAutoTopupEditTME;
          this.form_data.company_id = response.data.beneficiary.company_id;
          this.form_data.fixed_benefit_group_id = response.data.beneficiary.fixed_benefit_group_id;
          this.form_data.first_name = response.data.beneficiary.first_name;
          this.form_data.last_name = response.data.beneficiary.last_name;
          this.form_data.cnp = response.data.beneficiary.cnp;
          this.form_data.phone_number = response.data.beneficiary.phone_number;
          this.form_data.invitation_email = response.data.beneficiary.invitation_email;
          this.form_data.company_benefit_group_id = response.data.beneficiary.company_benefit_group_id;
          this.form_data.employee_number = response.data.beneficiary.employee_number;
          this.form_data.department = response.data.beneficiary.department;
          this.form_data.job_title = response.data.beneficiary.job_title;
          this.form_data.cost_center = response.data.beneficiary.cost_center;
          this.form_data.working_point = response.data.beneficiary.working_point;
          this.form_data.underage_children_nr = response.data.beneficiary.underage_children_nr;
          this.form_data.up_ro_address_id = response.data.beneficiary.up_ro_address_id;
          this.form_data.income_tax_percent = response.data.beneficiary.income_tax_percent;
          this.form_data.cass_percent = response.data.beneficiary.cass_percent;
          this.form_data.cas_percent = response.data.beneficiary.cas_percent;
          this.form_data.up_dejun_enable_auto_topup = response.data.beneficiary.up_dejun_enable_auto_topup;
          this.form_data.up_dejun_ticket_value = response.data.beneficiary.up_dejun_ticket_value;
          this.form_data.up_dejun_ticket_count = response.data.beneficiary.up_dejun_ticket_count;
          this.form_data.ppp3_deduction_limit = response.data.beneficiary.ppp3_deduction_limit;
          this.form_data.ppp3_personal_deduction_limit = response.data.beneficiary.ppp3_personal_deduction_limit;
          this.form_data.medical_insurance_deduction_limit = response.data.beneficiary.medical_insurance_deduction_limit;
          this.form_data.invitation_channel = response.data.beneficiary.invitation_channel;
          this.form_data.deleted_at = response.data.beneficiary.deleted_at;
          this.form_data.status = response.data.beneficiary.status;
          let thresholds;
          if (Object.values(response.data.beneficiary.thresholds).length) {
            thresholds = response.data.beneficiary.thresholds;
          } else {
            thresholds = response.data.beneficiary.general_thresholds;
            this.has_default_values.gym_membership_threshold = true;
          }

          if (thresholds) {
            this.form_data.thresholds.gym_membership = thresholds.find(function (item) {
              return item.code === 'gym_membership'
            });
          }

          this.form_data.budget = response.data.beneficiary.budget;
          this.form_data.telework_budget = response.data.beneficiary.telework_budget;
          this.form_data.ppp3_budget = response.data.beneficiary.ppp3_budget;
          this.form_data.time_sheet = response.data.beneficiary.last_time_sheet;
          // general
          this.beneficiaryData.name = response.data.beneficiary.first_name + ' ' + response.data.beneficiary.last_name;
          this.beneficiaryData.status = response.data.beneficiary.status;
          this.beneficiaryData.last_employment_date = response.data.beneficiary.last_employment_date;
          this.beneficiaryData.activated_at = response.data.beneficiary.activated_at;
          this.beneficiaryData.available_benefits = response.data.beneficiary.benefits_slim;
          this.beneficiaryData.benefit_configuration = response.data.beneficiary.benefit_configuration;
          let benefitStringConcat = '';
          for (let benefit of response.data.beneficiary.benefits_slim) {
            benefitStringConcat += benefit.name;
          }
          this.beneficiaryData.benefitsCharLength = benefitStringConcat.length;
          this.beneficiaryData.budget = {
            total_budget: response.data.beneficiary.budget.total_budget,
            budget_expiration_date: response.data.beneficiary.budget.budget_expiration_date,
            updated_at: response.data.beneficiary.budget.updated_at,
          };
          this.beneficiaryData.time_sheet = {
            working_days: response.data.beneficiary.last_time_sheet.working_days,
            consumed_days: response.data.beneficiary.last_time_sheet.consumed_days,
            month: response.data.beneficiary.last_time_sheet.month,
            year: response.data.beneficiary.last_time_sheet.year,
            updated_at: response.data.beneficiary.last_time_sheet.updated_at,
          };
          this.beneficiaryData.history = {
            budgets: response.data.budget_history,
            telework_budgets: response.data.telework_budget_history,
            ppp3: response.data.ppp3_history,
            ppp3_budgets: response.data.ppp3_budget_history,
            time_sheets: response.data.time_sheet_history,
            requests: {...response.data.order_requests, ...{company: response.data.beneficiary.company}},
            auto_top_ups: response.data.auto_top_ups,
            auto_topup_history: response.data.auto_topup_history
          };
          this._setGeneralDefaults(response.data.general_global_variables);

          this.companyId = response.data.beneficiary.company.id;
          this.company = response.data.beneficiary.company;
          this.benefit_groups = response.data.beneficiary.company.groups;
          this.fixed_benefit_groups = response.data.beneficiary.company.fixed_benefit_groups;
          this.delivery_addresses = response.data.beneficiary.company.addresses;
          this.form_options = response.data.form_options;
          this.initialLoading = false
          this.loading = false
        })
        .catch(function (error) {
          console.log(error)
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else if (error.response.status === 404) {
            window.location.href = '/multibeneficii/beneficiaries';
          } else {
            this.initialLoading = false
            this.loading = false
          }
        });
    },
    _setGeneralDefaults(globalVariables) {
      if (this.form_data.income_tax_percent === globalVariables.find(function (item) {
        return item.code === 'impozit-venit'
      }).value) {
        this.has_default_values.income_tax_percent = true;
      }

      if (this.form_data.cass_percent === globalVariables.find(function (item) {
        return item.code === 'cass'
      }).value) {
        this.has_default_values.cass_percent = true;
      }

      if (this.form_data.cas_percent === globalVariables.find(function (item) {
        return item.code === 'cas'
      }).value) {
        this.has_default_values.cas_percent = true;
      }

      if (this.form_data.ppp3_deduction_limit === globalVariables.find(function (item) {
        return item.code === 'ppp3'
      }).value) {
        this.has_default_values.ppp3_deduction_limit = true;
      }

      if (this.form_data.ppp3_personal_deduction_limit === globalVariables.find(function (item) {
        return item.code === 'ppp3'
      }).value) {
        this.has_default_values.ppp3_personal_deduction_limit = true;
      }

      if (this.form_data.medical_insurance_deduction_limit === globalVariables.find(function (item) {
        return item.code === 'medical_insurance'
      }).value) {
        this.has_default_values.medical_insurance_deduction_limit = true;
      }
    },
    _buildFormBudget(key) {
      if (this.form_data[key].total_budget === 0 && this.form_data[key].budget_expiration_date === null) {
        return null;
      } else {
        let date = null;
        if (this.form_data[key]['budget_expiration_date'] !== null) {
          date = moment(this.form_data[key]['budget_expiration_date']).format('DD/MM/YYYY');
        }
        return {
          total_budget: this.form_data[key].total_budget,
          budget_expiration_date: date
        };
      }
    },
    _buildFormTimeSheet() {
      if ((this.form_data.time_sheet.working_days === 0 && this.form_data.time_sheet.month === null && this.form_data.time_sheet.year === null)) {
        return null;
      } else {
        let returnData = {...this.form_data.time_sheet}
        returnData.working_days = parseInt(returnData.working_days);
        return returnData;
      }
    },
    _createBeneficiary() {
      let formData = {...this.form_data};
      if (this.company.type_code !== 'special_offers') {
        formData.ppp3_deduction_limit = (formData.ppp3_deduction_limit) ? parseFloat(formData.ppp3_deduction_limit) : null;
        formData.ppp3_personal_deduction_limit = (formData.ppp3_personal_deduction_limit) ? parseFloat(formData.ppp3_personal_deduction_limit) : null;
        formData.medical_insurance_deduction_limit = (formData.medical_insurance_deduction_limit) ? parseFloat(formData.medical_insurance_deduction_limit) : null;
        formData.budget = this._buildFormBudget('budget');
        formData.telework_budget = this._buildFormBudget('telework_budget');
        formData.ppp3_budget = this._buildFormBudget('telework_budget');
        formData.time_sheet = this._buildFormTimeSheet();
      }

      this.httpService.create(formData)
        .then((response) => {
          this.initialLoading = false;
          this.loading = true;
          this.$refs['status_change_modal'].setMessage(this.$t('flexben.benefit.beneficiary_created_successfully'), false, true);
          this.isFormEdited = false;
          localStorage.removeItem('selectedCompanyId');
          window.location.href = '/multibeneficii/beneficiaries';
        })
        .catch((error) => {
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else {
            if (error.response.status === 400 && typeof error.response.data.error_fields !== 'undefined') {
              this.$refs['status_change_modal'].closeModal();
              this.errorBag = {
                details: (typeof error.response.data.error_fields.details !== 'undefined') ? error.response.data.error_fields.details : {},
                budget: (typeof error.response.data.error_fields.budget !== 'undefined') ? error.response.data.error_fields.budget : {},
                telework_budget: (typeof error.response.data.error_fields.telework_budget !== 'undefined') ? error.response.data.error_fields.telework_budget : {},
                ppp3_budget: (typeof error.response.data.error_fields.ppp3_budget !== 'undefined') ? error.response.data.error_fields.ppp3_budget : {},
                time_sheet: (typeof error.response.data.error_fields.time_sheet !== 'undefined') ? error.response.data.error_fields.time_sheet : {},
              };
              this.scrollToTop();
            }

            this.initialLoading = false
            this.loading = false
          }
        });
    },
    _updateBeneficiary() {
      //prepare form
      let formData = {...this.form_data};
      if (this.company.type_code !== 'special_offers') {
        formData.ppp3_deduction_limit = (formData.ppp3_deduction_limit === 0) ? 0 : ((formData.ppp3_deduction_limit) ? parseFloat(formData.ppp3_deduction_limit) : null);
        formData.ppp3_personal_deduction_limit = (formData.ppp3_personal_deduction_limit === 0) ? 0 : ((formData.ppp3_personal_deduction_limit) ? parseFloat(formData.ppp3_personal_deduction_limit) : null);
        formData.medical_insurance_deduction_limit = (formData.medical_insurance_deduction_limit === 0) ? 0 : (formData.medical_insurance_deduction_limit) ? parseFloat(formData.medical_insurance_deduction_limit) : null;
        formData.budget = this._buildFormBudget('budget');
        formData.telework_budget = this._buildFormBudget('telework_budget');
        formData.ppp3_budget = this._buildFormBudget('ppp3_budget');
        formData.time_sheet = this._buildFormTimeSheet();
        formData.income_tax_percent = ('' + formData.income_tax_percent).length > 0 ? formData.income_tax_percent : null;
        formData.cass_percent = ('' + formData.cass_percent).length > 0 ? formData.cass_percent : null;
        formData.cas_percent = ('' + formData.cas_percent).length > 0 ? formData.cas_percent : null;
      }

      //reset errors
      this.errorBag = {
        details: {},
        budget: {},
        time_sheet: {},
      };

      this.httpService.update(this.beneficiaryId, formData)
        .then((response) => {
          this.loading = false;
          this.$refs['status_change_modal'].setMessage(this.$t('flexben.benefit.beneficiary_updated_successfully'), false, true);
          this.form_data.lastAutoTopupEditTME = response.data.auto_topup_history.lastAutoTopupEditTME;
          this.isFormEdited = false;
        })
        .catch((error) => {
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else {
            if (error.response.status === 400 && typeof error.response.data.error_fields !== 'undefined') {
              this.$refs['status_change_modal'].closeModal();
              this.errorBag = {
                details: error.response.data.error_fields.details,
                budget: error.response.data.error_fields.budget,
                time_sheet: error.response.data.error_fields.time_sheet,
                telework_budget: error.response.data.error_fields.telework_budget,
                ppp3_budget: error.response.data.error_fields.ppp3_budget,
              };
              this.scrollToTop();
            }

            this.initialLoading = false
            this.loading = false
          }
        });
    },
    _changeBeneficiaryStatus(newStatus) {
      this.loading = true;
      let formData = {
        status: newStatus,
      };
      if (newStatus === 'pending_delete') {
        let date = null;
        if (this.last_employment_date !== null) {
          date = new Date(this.last_employment_date);
          formData.last_employment_date = (date !== null) ? date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() : null ;
        }
      }
      this.httpService.changeStatus(this.beneficiaryId, formData)
        .then(function (response) {
          this.beneficiaryData.status = response.data.new_status
          this.loading = false
          this.$refs['status_change_modal'].setMessage(this.$t('flexben.beneficiaries.status_change_successfully'), false, true);
          let dateParts = formData.last_employment_date.split(".");
          let formattedDate = dateParts[0] + '-' + dateParts[1].padStart(2, '0') + '-' + dateParts[2];
          this.beneficiaryData.last_employment_date = formattedDate;

        }.bind(this))
        .catch(function (error) {
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else if (error.response.status === 400) {
            let errorFields = error.response.data.error_fields;
            if (typeof errorFields.status !== 'undefined') {
              this.$refs['status_change_modal'].setMessage('Status invalid', true, true);
            } else if (typeof errorFields.last_employment_date !== 'undefined') {
              this.$refs['status_change_modal'].setMessage(this.$t('flexben.formFieldError', {
                field: this.$t('flexbenBeneficiaryFormFields.last_employment_date'),
                reason: this.$t('flexben.formErrorReasons.' + errorFields.last_employment_date)
              }), true, true);
            }
            this.scrollToTop();
          } else if (error.response.status === 403) {
          } else {
            this.loading = false
          }
        }.bind(this));
    },
    _sendInvitationEmail() {
      this.loading = true;
      this.httpService.reInviteBeneficiary(this.beneficiaryId)
        .then(function (response) {
          this.$refs['status_change_modal'].setMessage(this.$t('flexben.beneficiaries.invitation_sent_successfully'), false, true);
          this.loading = false;
        }.bind(this))
        .catch(function (error) {
          this.$refs['status_change_modal'].setMessage(this.$t('flexben.beneficiaries.invitation_sent_error'), false, true);
          this.loading = false
          this.scrollToTop();
        }.bind(this));
    },
    _changeStatusToInvited() {
      this.httpService.changeBeneficiaryStatusToInvited(this.beneficiaryId)
        .then(function (response) {
          this.$refs['status_change_modal'].setMessage(this.$t('flexben.beneficiaries.change_status_to_invited_success'), false, true);
          this.loading = false;
          this.beneficiaryData.status = 'invited';
        }.bind(this))
        .catch(function (error) {
          this.$refs['status_change_modal'].setMessage(this.$t('flexben.beneficiaries.change_status_to_invited'), false, true);
          this.loading = false
          this.scrollToTop();
        }.bind(this));
    },
    _reActivateBeneficiary() {
      this.httpService.reActivateBeneficiary(this.beneficiaryId)
        .then(function (response) {
          this.$refs['status_change_modal'].setMessage(this.$t('flexben.beneficiaries.reActivateBeneficiary_success'), false, true);
          this.loading = false;
          this.beneficiaryData.status = 'active';
          this.beneficiaryData.last_employment_date = null;
        }.bind(this))
        .catch(function (error) {
          this.$refs['status_change_modal'].setMessage(this.$t('flexben.beneficiaries.change_status_error'), true, true);
          this.loading = false
          this.scrollToTop();
        }.bind(this));
    },
    goBack() {
      if (this.isFormEdited) {
        this.triggerAction('go_back');
      } else {
        window.location = '/multibeneficii/beneficiaries'
      }
    },
    confirmAction(lastEmploymentDate) {
      switch (this.modal.statusChange) {
        case 'form_submit':
          this.loading = true;
          this.errorBag = {
            details: {},
            budget: {},
            time_sheet: {},
          };
          if (this.edit) {
            this._updateBeneficiary();
          } else {
            this._createBeneficiary();
          }
          break;
        case 'go_back':
          window.location = '/multibeneficii/beneficiaries';
          break;
        case 'activate':
          this._changeBeneficiaryStatus('active');
          break;
        case 'suspend':
          this._changeBeneficiaryStatus('suspended');
          break;
        case 'delete':
          this.last_employment_date = lastEmploymentDate;
          this._changeBeneficiaryStatus('pending_delete');
          break;
        case 'reinvite':
          this._sendInvitationEmail();
          break;
        case 'toInvited':
          this._changeStatusToInvited();
          break;
        case 'reActivate':
          this._reActivateBeneficiary();
          break;
        default:
          console.log('no action');
          break;
      }
    },
    pageChangedHistoryBudget(page) {
      this.loading = true;
      httpFlexbenService.beneficiaries.history.loadBudgets(this.beneficiaryId, {
        page: page,
        perPage: this.beneficiaryData.history.perPage
      })
        .then(function (response) {
          this.beneficiaryData.history.budgets = response.data;
          this.loading = false
        }.bind(this))
        .catch(function () {
          this.initialLoading = false
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false
          }
        }.bind(this));
    },
    pageChangedHistoryTeleworkBudget(page) {
      this.loading = true;
      httpFlexbenService.beneficiaries.history.loadBudgets(this.beneficiaryId, {
        page: page,
        perPage: this.beneficiaryData.history.perPage
      })
        .then(function (response) {
          this.beneficiaryData.history.telework_budgets = response.data;
          this.loading = false
        }.bind(this))
        .catch(function () {
          this.initialLoading = false
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false
          }
        }.bind(this));
    },
    pageChangedHistoryPpp3Budget(page) {
      this.loading = true;
      httpFlexbenService.beneficiaries.history.loadBudgets(this.beneficiaryId, {
        page: page,
        perPage: this.beneficiaryData.history.perPage
      })
        .then(function (response) {
          this.beneficiaryData.history.ppp3_budgets = response.data;
          this.loading = false
        }.bind(this))
        .catch(function () {
          this.initialLoading = false
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false
          }
        }.bind(this));
    },
    pageChangedHistoryTimeSheets(page) {
      this.loading = true;
      httpFlexbenService.beneficiaries.history.loadTimeSheets(this.beneficiaryId, {
        page: page,
        perPage: this.beneficiaryData.history.perPage
      })
        .then(function (response) {
          this.beneficiaryData.history.time_sheets = response.data;
          this.loading = false
        }.bind(this))
        .catch(function () {
          this.initialLoading = false
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false
          }
        }.bind(this));
    },
    pageChangedHistoryRequests(page) {
      this.loading = true;
      httpFlexbenService.beneficiaries.history.loadRequests(this.beneficiaryId, {
        page: page,
        perPage: this.beneficiaryData.history.perPage
      })
        .then(function (response) {
          this.beneficiaryData.history.requests = response.data;
          this.loading = false
        }.bind(this))
        .catch(function () {
          this.initialLoading = false
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false
          }
        }.bind(this));
    },
    changePerPage(perPage, tab) {
      // this.params.page = 1;
      this.perPage = perPage;
      this.beneficiaryData.history.perPage = perPage;

      if (tab === 'budget') {
        this.pageChangedHistoryBudget(1);
      } else if (tab === 'time_sheet') {
        this.pageChangedHistoryTimeSheets(1);
      } else if (tab === 'request') {
        this.pageChangedHistoryRequests(1);
      } else {
        //there is no else case
      }
    },
    benefitsExpand() {
      this.showAllBenefits = !this.showAllBenefits;
    },
    triggerAction(action, saveSection = null) {
      this.modal.statusChange = action;
      this.form_data.save_section = saveSection;
      this.$refs['status_change_modal'].openModal(this.form_options.company.name, this.modal.statusChange);
    },
    disableTopUps(ids) {
      this.loading = true;
      httpFlexbenService.beneficiaries.history.disableAutoTopUps(this.beneficiaryId, {
        ids: ids
      })
        .then((response) => {
          this.beneficiaryData.history.auto_top_ups = response.data;
          this.loading = false
        })
        .catch(() => {
          this.initialLoading = false
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false
          }
        });
    },
    _getBenefitConfigInfo() {
      const dataConfiguration = this.beneficiaryData.benefit_configuration;
      const typesToCheck = ['up_dejun', 'up_culture', 'up_vacation', 'up_gift'];
      let companyGrossTax = this.form_options.company.gross_budget_taxation === true ? this.$t("flexben.beneficiaries.company.gross_budget_taxation_subtitle") : this.$t("flexben.beneficiaries.company.net_budget_taxation_subtitle");
      let output='<li class="text-12">General: '+companyGrossTax+'</li>';
      if (typeof dataConfiguration !== 'undefined' && dataConfiguration.length > 0) {
        Object.entries(dataConfiguration).forEach(([key, value]) => {
          if (typesToCheck.includes(value.type)) {
            output += '<li class="text-12">'+ this.$t('flexben.clients.benefits.'+value.type) + ': ';
            let applyAllTaxes = value.configuration.tax_settings.apply_all_taxes;
            if (typeof applyAllTaxes !== 'undefined') {
              if (applyAllTaxes === true && this.form_options.company.gross_budget_taxation === true) {
                output += this.$t('flexben.beneficiaries.company.gross_budget_taxation_budget') + '</li>';
              } else {
                output += this.$t('flexben.beneficiaries.company.gross_budget_taxation_salary') + '</li>';
              }
            }
          }
        });
      }
      return output;
    }
  },
  computed: {
    displayDate() {
      if (this.beneficiaryData.status === 'pending_delete' || this.beneficiaryData.status === 'active') {
        return this.beneficiaryData.last_employment_date || this.beneficiaryData.activated_at || '';
      } else {
        return '';
      }
    }
  }
};
</script>

<style src="@/assets/styles/flexben.css"></style>

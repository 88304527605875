<template>
  <div class="row mt-3 p-0">
    <div class="col-12 text-28">
      {{$t("flexben.beneficiaries.history_title")}}
    </div>
    <div class="col-12 mt-3">
      <b-tabs nav-class="flexben-tabs">
        <TabBeneficiaryHistoryBudget
          v-if="flxModuleCan('flexible_benefits',company_module_permissions)"
          :key="'budget'"
          :activeTab="activeTab"
          :tableData="history.budgets"
          @changePerPage="changePerPage"
          @pageChanged="pageChangedHistoryBudget"/>
        <TabBeneficiaryHistoryPPP3Budget
          v-if="flxModuleCan('flexible_benefits',company_module_permissions)"
          :key="'ppp3_budget'"
          :activeTab="activeTab"
          :tableData="history.ppp3_budgets"
          @changePerPage="changePerPage"
          @pageChanged="pageChangedHistoryPpp3Budget"/>
        <TabBeneficiaryHistoryTimeSheets
          v-if="flxModuleCan('flexible_benefits',company_module_permissions)"
          :key="'time_sheet'"
          :activeTab="activeTab"
          :tableData="history.time_sheets"
          @changePerPage="changePerPage"
          @pageChanged="pageChangedHistoryTimeSheets"/>
        <TabBeneficiaryHistoryRequests
          v-if="flxModuleCan(['flexible_benefits','telework'],company_module_permissions)"
          :key="'requests'"
          :activeTab="activeTab"
          :tableData="history.requests"
          :isAdmin="isAdmin"
          @changePerPage="changePerPage"
          @pageChanged="pageChangedHistoryRequests"/>
        <TabBeneficiaryHistoryTeleworkBudget
          v-if="flxModuleCan('telework',company_module_permissions)"
          :key="'telework_budget'"
          :activeTab="activeTab"
          :tableData="history.telework_budgets"
          @changePerPage="changePerPage"
          @pageChanged="pageChangedHistoryBudget"/>
        <TabBeneficiaryHistoryAutoTopUps
          v-if="flxModuleCan(['flexible_benefits','telework'],company_module_permissions)"
          :key="'auto_top_ups'"
          :activeTab="activeTab"
          :autoTopUps="history.auto_top_ups"
          @disableTopUps="disableTopUps"/>

        <TabBeneficiaryAllAutoTopUpsHistory
          v-if="flxModuleCan(['flexible_benefits','telework'],company_module_permissions)"
          :key="'all_auto_top_ups_history'"
          :activeTab="activeTab"
          :allHistory="history.auto_topup_history"
        />


      </b-tabs>
    </div>
  </div>
</template>

<script>
import {BTabs} from "bootstrap-vue";
import TabBeneficiaryHistoryBudget from "./TabBeneficiaryHistoryBudget";
import TabBeneficiaryHistoryTimeSheets from "./TabBeneficiaryHistoryTimeSheets";
import TabBeneficiaryHistoryRequests from "./TabBeneficiaryHistoryRequests";
import TabBeneficiaryHistoryAutoTopUps from "./TabBeneficiaryHistoryAutoTopUps";
import TabBeneficiaryHistoryTeleworkBudget from "@/components/flexben/beneficiaries/partials/administration/TabBeneficiaryHistoryTeleworkBudget";
import TabBeneficiaryHistoryPPP3Budget from "@/components/flexben/beneficiaries/partials/administration/TabBeneficiaryHistoryPPP3Budget.vue";
import TabBeneficiaryAllAutoTopUpsHistory from "@/components/flexben/beneficiaries/partials/administration/TabBeneficiaryAllAutoTopUpsHistory.vue";

export default {
  name: "BeneficiaryHistory",
  components: {
    TabBeneficiaryHistoryRequests,
    TabBeneficiaryHistoryTimeSheets,
    TabBeneficiaryHistoryBudget,
    TabBeneficiaryHistoryAutoTopUps,
    TabBeneficiaryAllAutoTopUpsHistory,
    TabBeneficiaryHistoryTeleworkBudget,
    TabBeneficiaryHistoryPPP3Budget,
    "b-tabs": BTabs,
  },
  props: {
    history: {
      type: Object,
      default: function () {
        return {
          budgets: {},
          telework_budgets: {},
          time_sheets: {},
          requests: {},
          auto_top_ups: [],
        }

      },
      required: true
    },
    company_module_permissions: {
      type: Array,
      default: function () {
        return [];
      },
      required: true
    },
    isAdmin:{
      required: true,
      default: false,
    }
  },
  data() {
    return {
      activeTab: 'budget',
    };
  },
  methods: {
    pageChangedHistoryBudget(page) {
      this.$emit("pageChangedHistoryBudget", page);
    },
    pageChangedHistoryTimeSheets(page) {
      this.$emit("pageChangedHistoryTimeSheets", page);
    },
    pageChangedHistoryRequests(page) {
      this.$emit("pageChangedHistoryRequests", page);
    },
    pageChangedHistoryTeleworkBudget(page) {
      this.$emit("pageChangedHistoryTeleworkBudget", page);
    },
    pageChangedHistoryPpp3Budget(page) {
      this.$emit("pageChangedHistoryPpp3Budget", page);
    },
    pageChangedHistoryPpp3(page) {
      this.$emit("pageChangedHistoryPpp3", page);
    },
    changePerPage(perPage, tab) {
      this.$emit('changePerPage', perPage, tab);
    },
    disableTopUps(ids) {
      this.$emit('disableTopUps', ids);
    },
  }
}
</script>
